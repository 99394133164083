@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Lost Late";
  src: url("./fonts/LOSTLATE.ttf") format("truetype");
}

@font-face {
  font-family: "Handwriting";
  src: url("./fonts/handwriting.ttf") format("truetype");
}

#root,
body,
html {
  height: 100%;
  width: 100%;
  background-color: #000;
}
